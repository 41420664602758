<!--
 * @Author: your name
 * @Date: 2020-11-24 11:48:38
 * @LastEditTime: 2020-12-17 15:50:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\home\menus.vue
-->
<template>
    <div class="menus-main-box">
        <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#676d74"
      text-color="#fff"
      active-text-color="#ffd04b">
      <el-menu-item @click='routerPushTo(item)' v-for="(item,index) of returnMenus" :key='index' :index="item.path">
        <span slot="title">{{item.text}}</span>
      </el-menu-item>
    </el-menu>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            menus:[{
                text:this.$t('project.project'),
                path:'/projectList'
            },
            {
                text:'project.PV.own',
                path:'/adminModule'
            }],
            adminMenus:[{
                text:this.$t('project.data.inverter'),
                path:'/adminInverter'
            },
            {
                text:this.$t('project.PV'),
                path:'/adminModule'
            },
            {
                text:this.$t('project.battery'),
                path:'/adminBattery'
            },
            {
                text:this.$t('project.evcharger'),
                path:'/adminEvCharger'
            },
            {
                text:this.$t('project.heatpump'),
                path:'/adminHeatPump'
            },
            {
                text:this.$t('project.other-devices'),
                path:'/adminDevice'
            },
            {
                text:this.$t('project.data.Battery-Compatibility'),
                path:'/adminBatteryCompatibility'
            },
            {
                text:this.$t('city.menus'),
                path:'/adminCity'
            },{
                text:this.$t('connon.menus'),
                path:'/adminCountry'
            },{
                text:this.$t('user.menus'),
                path:'/userManage'
            },{
                text:this.$t('superAdmin'),
                path:'/adminManage'
            },{
                text:this.$t('Recommended_model'),
                path:'/adminrecommend'
            },
        ],
            nowPath:''
        };
    },
    computed: {
        returnMenus(){
            return sessionStorage.getItem('userType') == 16? this.adminMenus:this.menus
        }
    },
    watch: {//使用watch 监听$router的变化
        $route(to, from) {
            this.nowPath = to.fullPath
        }
    },
    created() {

    },
    mounted() {
        this.nowPath = this.$route.fullPath
        // if(this.nowPath == '/home'){
        //     let userType =  sessionStorage.getItem('userType')
        //     if(userType == 16){
        //         this.$router.push({path:this.adminMenus[0].path})
        //     }else{
        //         this.$router.push({path:this.menus[0].path})
        //     }
        // }
    },
    methods: {
        routerPushTo(item){
            if(item.path == this.nowPath){
                return
            }
            this.$router.push({path:item.path})
            this.nowPath = item.path
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }
    },
};
</script>

<style  lang="less" >
.menus-main-box{
    background-color: #676d74;
    height: 100%;
    .el-menu{
        border-right: none;
    }
    .el-menu-item.is-active {
        background-color: rgb(131, 138, 146) !important;
    }
}
</style>

