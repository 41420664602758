<!--
* @description 
* @fileName modal_user.vue
* @author yanghong
* @date 2020/11/24 17:30:06
!-->
<template>
    <div>
        <el-dialog
            :title="$t('userInfo.info')"
            :visible="visibleUserInfo"
            width="40%"
            @close="()=>{$store.commit('setVisibleUserInfo',false)}">
            <el-row :gutter="24">
                <el-col :span="20">
                    <el-form ref="form" :model="form" label-width="180px" >
                        <el-form-item :label="$t('user.email')" prop="email">
                            <el-input v-model="form.email" placeholder="" readonly></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('system.user.name')">
                            <el-input v-model="form.name" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('userInfo.company')">
                            <el-input v-model="form.corpName" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('userInfo.phone')">
                            <el-input v-model="form.phone" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('userInfo.timezone')" prop="countryId" :rules="[{ required: true, message: $t('remind.not-null')}]">
                            <el-select v-model="form.countryId" filterable :placeholder="$t('common.msg.select.please')" clearable>
                                <el-option
                                v-for="item in selectCountryData"
                                :key="item.countryID"
                                :label="item.countryNameInternational"
                                :value="item.countryID">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="()=>{$store.commit('setVisibleUserInfo',false)}">{{$t('system.button.cancel')}}</el-button>
                <el-button type="primary" @click="save('form')">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {

    },
    data() {
        let user=JSON.parse(sessionStorage.getItem('userInfo'));
        return {
            form:{
                userId:user.userId,
                email:user.email,
                name:user.name,
                corpName:user.corpName,
                phone:user.phone,
                countryId:user.countryId
            },
            selectCountryData:[]
        };
    },
    computed: {
        visibleUserInfo(){
            return this.$store.state.sysStore.visibleUserInfo
        }
    },
    created() {

    },
    mounted() {
        this.getCountryList()
    },
    methods: {
        async getCountryList(){
            let res = await this.API.getCountryList({})
            this.selectCountryData=res
        },
        async submitForm(){
            let res = await this.API.updateUser(this.form)
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.$store.commit('setVisibleUserInfo',false)
                this.$emit('uploadData',true)
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },
        save(formName){
            this.$refs[formName].validate((valid) => {
              if (valid) {
                  this.submitForm()
              } else {
                console.log('error submit!!');
                return false;
              }
            });
        },
    },
};
</script>

<style  lang="less" scoped>

</style>