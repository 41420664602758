<!--
 * @Author: your name
 * @Date: 2020-11-24 11:26:15
 * @LastEditTime: 2020-12-16 19:09:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\home\header.vue
-->
<template>
    <div class="header-mian-box">
        <div class="header-logo">
            <div class="logo">
            </div>
            <div class="title">
                Solax Design
            </div>
        </div>
        <div class="lan-box nav">
            <ul>
                <li :class="$route.path == '/projectList'?'active':''" v-if='userType != 16'>
                    <div class="login-out" @click='routePush("/projectList")'>
                        {{$t('project.project')}}
                    </div>
                </li>
                <li :class="$route.path == '/adminModule'?'active':''" v-if='userType != 16'>
                    <div class="login-out" @click='routePush("/adminModule")' >
                        {{$t('link.setting')}}
                    </div>
                </li>
                <li>
                    <el-dropdown>
                        <span class="el-dropdown-link">
                            <i class="el-icon-user-solid"></i> {{$t('user.menus')}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-show="userType!=16"><div @click="()=>{$store.commit('setVisibleUserInfo',true)}">{{$t('userInfo.info')}}</div></el-dropdown-item>
                            <el-dropdown-item><div @click="()=>{$store.commit('setVisibleEdit2',true)}">{{$t('userInfo.reset.password')}}</div></el-dropdown-item>
                            <el-dropdown-item v-show="userType!=16"><div @click="()=>{$store.commit('setVisibleReport',true)}">{{$t('userInfo.report.settings')}}</div></el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </li>
                <li>
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{$t('lang.select')}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="zh_CN">中文</el-dropdown-item>
                            <el-dropdown-item command="en_US">English</el-dropdown-item>
                            <el-dropdown-item command="ja_JP">日本語</el-dropdown-item>
                            <el-dropdown-item command="fr_FR">Français</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </li>
                <li>
                    <div class="login-out" @click='loginOut'>
                        <i class="el-icon-switch-button"></i>
                        {{$t('project.logout')}}
                    </div>
                </li>
            </ul>
            
        </div>
        <modal-user></modal-user>
        <modal-report></modal-report>
        <rebuildPassword></rebuildPassword>
    </div>
</template>

<script>
import modalUser from '../userInfo/modal_user.vue'
import rebuildPassword from '../adminManage/rebuildPassword'
import modalReport from '../userInfo/modal_report'
import Modal_report from '../userInfo/modal_report.vue';
export default {
    components: {
        modalUser,
        modalReport,
        Modal_report,
        rebuildPassword
    },
    data() {
        return {
            userType:sessionStorage.getItem('userType'),
            nowPath:''
        };
    },
    computed: {
        // userType(){
        //     return sessionStorage.getItem('userType')
        // }
    },
    watch: {
        $route(to, from) {
            this.nowPath = to.fullPath
        }
    },
    created() {

    },
    mounted() {
    },
    methods: {
        routePush(url){
            this.$router.push({path:url})
        },
        loginOut(){
            sessionStorage.removeItem('token')
            this.$router.push({path:'/login'})
        },
        handleCommand(lang){
            // this.$i18n.locale=lang;
            localStorage.setItem('lang',lang);
            localStorage.setItem('lan',lang);
            location.reload();
        }
    },
};
</script>

<style  lang="less" scoped>
.header-mian-box{
    .header-logo{
        float: left;
    }
    .logo{
        float: left;
        width: 147px;
        height: 50px;
        background: url(../../assets/img/logo.png) no-repeat left top;
        margin: 8px 0 0 8px;
        background-size: 200%;
    }
    .title{
        font-size: 27px;
        border-left: 1px solid #999;
        padding-left: 20px;
        margin: 15px 20px;
        display: inline-block;
        color: #fff;
        font-style:oblique;
    }
    .nav{
        float: right;
        li{
            float: left;
            list-style: none;
            color:#a9a9a9;

            &:hover{
                cursor: pointer;
                background: #434950;
                color:#fff;
                .el-dropdown-link{
                    color:#fff;
                }
            }
            
        }
        .login-out{
            padding: 19px;
        }
        .el-dropdown-link{
            padding: 19px;
            display: inline-block;
            font-size: 15px;
            color:#a9a9a9;
        }
        .active{
            cursor: pointer;
            background: #434950;
            color: #fff;
        }
    }
}
</style>
