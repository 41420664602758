<!--
 * @Author: your name
 * @Date: 2020-11-24 11:18:05
 * @LastEditTime: 2020-12-09 12:08:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\home\home.vue
-->
<template>
    <div class="home-main-box-big">
        <el-container style="height:100%;">
            <el-header style='background-color: #545c64;padding:0'>
                <headerBox></headerBox>
            </el-header>
            <el-container>
                <el-aside :width="userType == 16 ? '200px':'0px'" style="height:100%;">
                    <menusBox v-if='userType == 16'></menusBox>
                </el-aside>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import headerBox from './header'
import menusBox from './menus'
export default {
    components: {
        headerBox,
        menusBox
    },
    props: {

    },
    data() {
        return {

        };
    },
    computed: {
        userType(){
            return sessionStorage.getItem('userType')
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {
        if(this.$route.fullPath == '/home'){
            //超级管理员进来默认到逆变器页面
            if(sessionStorage.getItem('userType') == 16){
                this.$router.push({path:'/adminInverter'}) 
            }else{
                this.$router.push({path:'/projectList'}) 
            }
        }
    },
    methods: {

    },
};
</script>

<style  lang="less" scoped>
.home-main-box-big{
    height: 100%;
}
.el-form .el-form-item--small .el-form-item__error{
    width: 62px;
}
</style>
