<!--
* @description 
* @fileName modal_user.vue
* @author yanghong
* @date 2020/11/24 17:30:06
!-->
<template>
    <div>
        <el-dialog
            :title="$t('userInfo.report.settings')"
            :visible="visibleReport"
            width="800px"
            @open="getReport"
            @close="()=>{$store.commit('setVisibleReport',false)}">
            <el-row :gutter="24">
                <el-col :span="22">
                    <el-form ref="form" :model="form" label-width="250px" >
                        <el-form-item :label="$t('userInfo.contact.info')">
                            <el-input type="textarea" v-model="form.corpInfo" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('userInfo.company.info')">
                            <el-input type="textarea" v-model="form.contactInfo" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item  prop="country">
                            <div slot="label" >
                                <el-checkbox v-model="form.isShowLogo" false-label="0" true-label="1">logo</el-checkbox>
                            </div>
                            <baseFile v-model='form.uploadFile' @change='returnImgUrl'></baseFile>
                            <img :src="imgBaseUrl" width="200px" height="200px" class="margintop_10" @click="preview">
                            <p class="font-size:12px">{{$t('logo.image.type')}}</p>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="()=>{$store.commit('setVisibleReport',false)}">{{$t('system.button.cancel')}}</el-button>
                <el-button type="primary" @click="save('form')">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import baseFile from '../../components/common/baseFileInput'
export default {
    components: {
        baseFile
    },
    props: {
    },
    data() {
        return {
            form:{
                corpInfo:'',
                contactInfo:'',
                isShowLogo:"1",
                uploadFile:''
            },
            dialogImageUrl: '',
            dialogVisible: false,
            actionUrl:'',
            imgBaseUrl:'',
            showImage:true,
            headers:{
                'token': sessionStorage.getItem('token') || ''
            }
        };
    },
    computed: {
        visibleReport(){
            return this.$store.state.sysStore.visibleReport
        }
    },
    created() {

    },
    mounted() {
    },
    methods: {
        async getLogo(fileName){
            let res = await this.API.getLogo({fileName:fileName})
            this.imgBaseUrl='data:image/gif;base64,'+res
        },
        async getReport(){
            let res = await this.API.getReport({})
            // console.log(res)
            if(res.success){
                this.form.corpInfo=res.object.corpInfo
                this.form.contactInfo=res.object.contactInfo
                this.form.isShowLogo=res.object.isShowLogo

                this.getLogo(res.object.logoUrl)
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },
        async submitForm(){
            if((this.form.isShowLogo==1&&this.form.uploadFile=='')||(this.form.isShowLogo==true&&this.form.uploadFile=='')){
                if(this.form.uploadFile==''){
                    this.$message.error(this.$t('select.logo'))
                }
            }else{
                let res = await this.API.setReport(this.form)
                if(res.success){
                    this.$message({
                        message: this.$t('operate.result.success'),
                        type: 'success'
                    });
                    this.$store.commit('setVisibleReport',false)
                    this.$emit('uploadData',true)
                }else{
                    this.$message({
                        message: this.$t('operate.result.fail'),
                        type: 'warning'
                    });
                }
            }
        },
        save(formName){
            this.$refs[formName].validate((valid) => {
              if (valid) {
                  this.submitForm()
              } else {
                console.log('error submit!!');
                return false;
              }
            });
        },
        returnImgUrl(){
            let that = this
            if(this.form.uploadFile){
                var reader=new FileReader();
                reader.readAsDataURL(this.form.uploadFile);
                reader.onload=function (e) {
                     that.imgBaseUrl=e.target.result;
                }
            }
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        preview(){
            this.dialogImageUrl = this.imgBaseUrl;
            this.dialogVisible = true;
        }
    },
};
</script>

<style  lang="less" scoped>

  .base-file-input-main-box{
    width: 100%;
    position: relative;
    .file-old{
        width: 90%;
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        opacity: 0;
    }
}
</style>