<!--
 * @Author: your name
 * @Date: 2020-11-27 11:09:25
 * @LastEditTime: 2020-12-17 17:00:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminManage\rebuildPassword.vue
-->
<template>
    <div>
        <el-dialog
            :title="$t('operate.user.modify.password')"
            :visible="visibleEdit2"
            width="40%"
            @close="()=>{$store.commit('setVisibleEdit2',false)}"
            :close-on-click-modal='false'
            >
            <el-row :gutter="20">
                <el-col :span="16" :offset="3">
                    <el-form ref="form" :model="form" label-width="180px" >
                        <el-form-item :label="$t('password.old')" prop="oldPassword" :rules="[{ required: true, message: $t('remind.not-null')},{ validator: validate_oldPwd, trigger: 'blur' }]">
                            <el-input type="password" v-model="form.oldPassword"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('password.new')" prop="newPassword" :rules="[{ required: true, message: $t('remind.not-null')},{min:6,max:20,message:$t('index_js_lengthRules')}]">
                            <el-input type="password" v-model="form.newPassword"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('password.new.confirm')" prop="confirmPassword" :rules="[{ required: true, message: $t('remind.not-null')},{validator:test_regPaw,trigger:'blur'}]">
                            <el-input type="password" v-model="form.confirmPassword"></el-input>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="()=>{$store.commit('setVisibleEdit2',false)}">{{$t('system.button.cancel')}}</el-button>
                <el-button type="primary" @click="save('form')">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {

    },
    data() {
        this.test_regPaw = (rule, value, callback)=>{
            if(value != this.form.newPassword){
                callback(new Error(this.$t('user.msg_different_twice')))
            }else{
                callback()
            }
        }
        this.validate_oldPwd = (rule, value, callback) => {
            if ((value)!=this.userData.password) {
                callback(new Error(this.$t('user.msg_different_pwd')));
            }else{
                callback()
            }
        };
        return {
            form:{
                confirmPassword:'',
                oldPassword:'',
                newPassword:'',
            },
            userData:{
                password:localStorage.getItem('password')
            }
        };
    },
    computed: {
        visibleEdit2(){
            return this.$store.state.sysStore.visibleEdit2
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        async systemUpdatePassword(){
            let res = await this.API.systemUpdatePassword(this.form)
            if(res.success){
                this.$message({
                    message: this.$t('system.user.reset-success'),
                    type: 'success'
                });
                this.$store.commit('setVisibleEdit2',false)
            }else{
                this.$message({
                    message: this.$t('system.user.reset-fail'),
                    type: 'warning'
                });
            }
        },
        save(formName){
            this.$refs[formName].validate((valid) => {
              if (valid) {
                  this.systemUpdatePassword()
              } else {
                console.log('error submit!!');
                return false;
              }
            });
        },
    },
};
</script>

<style  lang="less" scoped>

</style>

